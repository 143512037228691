$PI: 3.14159265359; // Define a variable for PI

.loader-container {
    background: linear-gradient(135deg, #26836d, #3ea889 25%, #74c2ee 50%, #3ea889 75%, #26836d);
    padding: 20px; /* Adjust as needed */
    border-radius: 10px; /* Add some border radius for a rounded look */
}

.hour-loader {
    margin: auto;
    padding: 10px;
    width: 100vmin;
    max-width: 370px;
    display: flex;
    align-items: center;
    margin-top: 2rem;
}

.half-circle {
    stroke-dasharray: $PI * 100;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
    stroke-dashoffset: 264;
    animation:
        rotateCircle 2.2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite both,
        delayedSnake 2.2s ease-in-out infinite;
}

.hourglass {
    stroke-dasharray: 210;
    animation: snake 3s linear infinite both;
}

@keyframes rotateCircle {
    0% {
        transform: rotate(-105deg);
    }
    5% {
        transform: rotate(-105deg);
    }
    95% {
        transform: rotate(105deg);
    }
    100% {
        transform: rotate(105deg);
    }
}

@keyframes delayedSnake {
    0% {
        stroke-dashoffset: 260;
    }
    50% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: -260;
    }
}

@keyframes snake {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: 420;
    }
}
